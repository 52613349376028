<template>
  <section class="course">
    <h2>歷程總覽</h2>
    <table class="course-table">
      <tr>
        <td class="td-head">諮詢編號</td>
        <td class="td-head">日期</td>
        <td class="td-head">問題類別</td>
        <td class="td-head">單位</td>
        <td class="td-head">狀態</td>
      </tr>
      <tr>
        <td>9894430112</td>
        <td>2021/12/10</td>
        <td>法律類</td>
        <td>勵馨基金會</td>
        <td style="color:#9D4CFF">進行中</td>
      </tr>
   </table>
   <table class="course-table border-0">
    <tr>
      <td class="td-head">諮詢編號</td>
      <td class="td-head">日期</td>
      <td class="td-head">問題類別</td>
      <td class="td-head">單位</td>
      <td class="td-head">狀態</td>
    </tr>
    <tr>
      <td class="border-bottom-left-radius ">9894430112</td>
      <td>2021/12/10</td>
      <td>法律類</td>
      <td>勵馨基金會</td>
      <td style="color:red" class="border-bottom-right-radius">已結束</td>
    </tr>
    <tr>
      <td class="border-0" colspan="5">
        <a class="checkLog" href="#">查看諮詢紀錄</a>
      </td>
    </tr>
   </table>
  </section>
</template>
